import { useContext, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import { auth } from "../../../../config";
import { SiteContext } from "../../../../contexts/SiteContext";
import { getWordGender } from "../../../../utils";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.light};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.primary.main, 0.6)}
`
);

function HeaderUserbox() {
  const theme = useTheme();
  const {
    user,
    tiposDeAsistentes = {},
    eventData,
    defaultEventID,
    deleteLocalInfo,
  } = useContext(SiteContext);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="info" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="rounded"
          alt={user?.name}
          children={user?.initials}
          sx={{
            bgcolor: theme.palette.primary.dark,
            color: theme.colors.alpha.trueWhite[100],
          }}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.musicos &&
              user.musicos[eventData?.id ?? defaultEventID] === true
                ? "Músico"
                : user?.consejerias &&
                  user.consejerias[eventData?.id ?? defaultEventID] === true
                ? "Consejer" + getWordGender(user.genero ?? "M")
                : tiposDeAsistentes[user?.rol ?? "0"]}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            variant="rounded"
            alt={user?.name}
            children={user?.initials}
            sx={{
              bgcolor: theme.palette.primary.dark,
              color: theme.colors.alpha.trueWhite[100],
            }}
          />
          <UserBoxText>
            <UserBoxLabel
              variant="body1"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {user?.name}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.musicos &&
              user.musicos[eventData?.id ?? defaultEventID] === true
                ? "Músico"
                : user?.consejerias &&
                  user.consejerias[eventData?.id ?? defaultEventID] === true
                ? "Consejer" + getWordGender(user.genero ?? "M")
                : tiposDeAsistentes[user?.rol ?? "0"]}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          {/* <ListItem button to="/management/profile/details" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem button to="/dashboards/messenger" component={NavLink}>
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary="Messenger" />
          </ListItem> */}
          <ListItem
            button
            to="/home/perfil"
            component={NavLink}
            onClick={handleClose}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Mi Perfil" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={async () => {
              await auth.signOut();
              deleteLocalInfo();
              //navigate("/login", { replace: true });
            }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Cerrar Sesión
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
