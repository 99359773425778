import { onAuthStateChanged } from "firebase/auth";
import { get, onValue, ref, set } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SidebarLayout from "../components/HomeSidebar";
import { auth, database } from "../config";
import { SiteContext } from "../contexts/SiteContext";
import { ThemeContext } from "../theme/ThemeProvider";

export const Home = () => {
  const navigate = useNavigate();
  const setTheme = useContext(ThemeContext);
  const {
    user,
    setUser,
    users = {},
    setUsers,
    iglesias,
    setIglesias,
    usersCedulas,
    setUserCedulas,
    eventData,
    lugares,
    setLugares,
    dormitoriosAsignados,
    setDormitoriosAsignados,
    eventos,
    setEventos,
    tiposDeAsistentes,
    setTiposDeAsistentes,
    defaultEventID,
    eventParticipantsGetter,
    setEventParticipantsGetter,
    waSession,
    setWaSession,
  } = useContext(SiteContext);
  const [updatedParticipantData, setUpdatedParticipantData] = useState();
  const [subscriptions, setSubscriptions] = useState({});

  const setUserListData = (uid, data) => {
    if (
      !data &&
      !users[uid] &&
      user.rol !== "admin" &&
      user.rol !== "checkIn"
    ) {
      const publicData = [
        "nombres",
        "apellidos",
        "celular",
        "instagram",
        "consejerias",
        "genero",
        "fecha_de_nacimiento",
      ];
      const userHabitacionesRef = ref(database, `users/${uid}/habitaciones`);
      console.log(`* [agrego listener de ${uid}] *`);
      onValue(userHabitacionesRef, async (habSnapshot) => {
        const data = {};
        const habitaciones = habSnapshot.val();
        data["habitaciones"] = habitaciones;
        const promesas = publicData.map((prop) => {
          const propRef = ref(database, `users/${uid}/${prop}`);
          return get(propRef);
        });
        const datos = await Promise.all(promesas);
        publicData.forEach((prop, i) => {
          data[prop] = datos[i].val();
        });

        console.log(
          `[Pedí data de habitaciones de ${data.nombres} ${data.apellidos}]`
        );
        setUpdatedParticipantData({ uid, data });
      });
    } else if (data) {
      // Forzamos a que JS lea DOS niveles de props como objetos, no como arreglos
      Object.keys(data || {}).forEach((prop) => {
        let temp = {};
        if (Array.isArray(data[prop])) {
          data[prop].forEach((subprop, i) => {
            temp[i] = subprop;
          });
        } else {
          temp = data[prop];
        }
        data[prop] = temp;
      });
      if (data) {
        data["uid"] = uid;
        data["initials"] = (data.nombres[0] + data.apellidos[0]).toUpperCase();
      }
      users[uid] = data;
      setUsers({ ...users });
    }
  };

  useEffect(() => {
    if (updatedParticipantData) {
      setUserListData(updatedParticipantData.uid, updatedParticipantData.data);
    }
  }, [updatedParticipantData]);

  let userDateSubs, subscriber;
  useEffect(() => {
    if (!subscriber) {
      subscriber = onAuthStateChanged(auth, async (usuario) => {
        if (usuario && !user) {
          // User is signed in
          const usuarioRef = ref(database, `users/${usuario.uid}`);
          console.log("* [agrego listener de usuario actual] *");
          if (!userDateSubs) {
            userDateSubs = onValue(usuarioRef, (snapshot) => {
              console.log(`[Pedí data de usuario actual]`);
              const data = { ...snapshot.val(), uid: usuario.uid };
              const newRol = data.eventos
                ? data.eventos[data.lastEventID ?? defaultEventID] ?? "0"
                : "0";
              if (data.rol !== newRol) {
                set(ref(database, `users/${usuario.uid}/rol`), newRol);
              } else {
                setUser({
                  ...data,
                  name: `${data.nombres.split(" ")[0]} ${
                    data.apellidos.split(" ")[0]
                  }`,
                  initials: (data.nombres[0] + data.apellidos[0]).toUpperCase(),
                  type: tiposDeAsistentes
                    ? tiposDeAsistentes[data?.rol ?? "0"]
                    : "Desconocido",
                });
                setUpdatedParticipantData({
                  uid: usuario.uid,
                  data: snapshot.val(),
                });
              }
            });
          }
        } else if (!usuario) {
          navigate("/login", { replace: true });
        }
      });
    }
    return () => {
      if (!window.location.pathname.startsWith("/home")) {
        console.log("CANCELO LOS LISTENERS PRINCIPALES");
        if (typeof subscriber === "function") subscriber();
        if (typeof userDateSubs === "function") userDateSubs();
      }
    };
    // eslint-disable-next-line
  }, []);

  let subs1, subs2, subs3, subs4, subs5, subs6;
  useEffect(() => {
    if (
      user &&
      !iglesias /* Para no volver a agregar listeners cuando ya existen */
    ) {
      console.log("* [agrego listener de iglesias] *");
      const churchesRef = ref(database, `iglesias`);
      subs1 = onValue(churchesRef, (snapshot) => {
        console.log(`[Pedí data de listado de iglesias]`);
        const data = {};
        // Forzamos a que JS lea como objeto, no como arreglos
        Object.keys(snapshot.val()).forEach((prop) => {
          data[prop.toString()] = snapshot.val()[prop];
        });
        setIglesias(data);
      });
    }
    if (
      user &&
      !usersCedulas /* Para no volver a agregar listeners cuando ya existen */
    ) {
      console.log("* [agrego listener de usersCedulas] *");
      const userCedulasRef = ref(database, `usersCedulas`);
      subs2 = onValue(userCedulasRef, (snapshot) => {
        console.log(`[Pedí data de listado de usersCedulas]`);
        setUserCedulas(snapshot.val());
      });
    }
    if (
      user &&
      !lugares /* Para no volver a agregar listeners cuando ya existen */
    ) {
      console.log("* [agrego listener de lugares] *");
      const lugaresRef = ref(database, `lugares`);
      subs5 = onValue(lugaresRef, (snapshot) => {
        console.log(`[Pedí data de listado de lugares]`);
        setLugares(snapshot.val() ?? {});
      });
    }
    if (
      user &&
      !tiposDeAsistentes /* Para no volver a agregar listeners cuando ya existen */
    ) {
      console.log("* [agrego listener de tiposDeParticipantes] *");
      const tiposRef = ref(database, `tiposDeParticipantes`);
      subs3 = onValue(tiposRef, (snapshot) => {
        console.log(`[Pedí data de tipos de participantes]`);
        const data = {};
        // Forzamos a que JS lea como objeto, no como arreglos
        Object.keys(snapshot.val()).forEach((prop) => {
          data[prop.toString()] = snapshot.val()[prop];
        });
        setTiposDeAsistentes(data);
      });
    }
    if (
      user &&
      !waSession /* Para no volver a agregar listeners cuando ya existen */
    ) {
      console.log("* [agrego listener de wa_session] *");
      const waSessionRef = ref(database, `wa_session`);
      subs6 = onValue(waSessionRef, (snapshot) => {
        console.log(`[Pedí data de wa_session]`);
        setWaSession(snapshot.val() ?? {});
      });
    }
    return () => {
      if (!window.location.pathname.startsWith("/home")) {
        console.log("CANCELO LOS LISTENERS");
        if (typeof subs1 === "function") subs1();
        if (typeof subs2 === "function") subs2();
        if (typeof subs3 === "function") subs3();
        if (typeof subs4 === "function") subs4();
        if (typeof subs5 === "function") subs5();
        if (typeof subs6 === "function") subs6();
      }
    };
    /* eslint-disable-next-line */
  }, [user]);

  useEffect(() => {
    if (
      user &&
      !eventos /* Para no volver a agregar listeners cuando ya existen */
    ) {
      console.log("* [agrego listener de eventos] *");
      const eventsRef = ref(database, `eventos`); //A futuro hacer algo parecido al listado de usuario
      subs4 = onValue(eventsRef, (snapshot) => {
        console.log(`[Pedí data de listado de eventos]`);
        const evts = snapshot.val();
        Object.keys(evts).forEach((k) => {
          const fechaInicio = new Date(evts[k].fecha_inicio);
          const fechaFin = new Date(evts[k].fecha_fin);
          evts[k].isActive = fechaFin >= new Date();
          evts[k].isHappening = fechaInicio <= new Date();
          evts[k].id = k;
        });
        setEventos(evts);
      });
    } else if (user && eventos && eventParticipantsGetter) {
      console.log(
        "Obteniendo participantes del evento",
        eventParticipantsGetter ?? "N/A"
      );
      if (["admin", "checkIn"].includes(user?.rol) && eventos) {
        let participantes = {};
        if (eventParticipantsGetter === "ALL") {
          Object.keys(eventos).forEach((evt) => {
            participantes = {
              ...participantes,
              ...eventos[evt]?.participantes,
            };
          });
        } else {
          participantes =
            eventos[eventParticipantsGetter + ""]?.participantes || {};
        }

        Object.keys(participantes).forEach((uid) => {
          if (!subscriptions[uid] && uid !== user.uid) {
            const usersRef = ref(database, `users/${uid}`);
            console.log(`* [agrego listener de ${uid}] *`);
            subscriptions[uid] = onValue(usersRef, (userSnapshot) => {
              const data = userSnapshot.val();
              console.log(`[Pedí data de ${data?.nombres} - ${data?.correo}]`);
              setUpdatedParticipantData({ uid, data });
            });
            setSubscriptions({ ...subscriptions });
          }
        });
      }
      setEventParticipantsGetter();
    }

    return () => {
      if (!window.location.pathname.startsWith("/home")) {
        Object.values(subscriptions).forEach((foo) => {
          if (typeof foo === "function") foo();
        });
        console.log("ANULÉ TODAS LAS SUBSCRIPCIONES");
      }
    };
    /* eslint-disable-next-line */
  }, [user, eventos, eventParticipantsGetter]);

  let dormitoriosSubs;
  useEffect(() => {
    if (eventData) {
      setTheme(eventData.themeName);
      if (eventData.id !== user.lastEventID) {
        console.log(`ACTUALIZO EL EVENTO DEL USUARIO A ${eventData.id}`);
        set(ref(database, `users/${user.uid}/lastEventID`), eventData.id);
      }
    }
    /*
    if (eventData && user && eventos) {
      if (
        eventData.id !== user.lastEventID ||
        user.eventos[eventData.id] !== user.rol
      ) {
        console.log(`ACTUALIZO EL EVENTO DEL USUARIO A ${eventData.id}`);
        set(ref(database, `users/${user.uid}/lastEventID`), eventData.id);
        const dormitoriosRef = ref(
          database,
          `dormitoriosAsignados/${eventData.id}`
        );
        if (dormitoriosSubs) {
          dormitoriosSubs();
          console.log("* cancelo listener de Dormitorios Asignados *");
        }
        console.log("* [agrego listener de Dormitorios Asignados] *");
        dormitoriosSubs = onValue(dormitoriosRef, (dormitoriosSnapshot) => {
          const data = dormitoriosSnapshot.val();
          console.log(`[Pedí los dormitorios de ${eventData.nombre}]`);
          setDormitoriosAsignados({
            ...dormitoriosAsignados,
            [eventData.id]: data,
          });
        });
      }
    }*/
    if (eventData && !dormitoriosSubs) {
      const dormitoriosRef = ref(
        database,
        `dormitoriosAsignados/${eventData.id}`
      );
      console.log("* [agrego listener de Dormitorios Asignados] *");
      dormitoriosSubs = onValue(
        dormitoriosRef,
        (dormitoriosSnapshot) => {
          const data = dormitoriosSnapshot.val();
          console.log(`[Pedí los dormitorios de ${eventData.nombre}]`);
          setDormitoriosAsignados({
            ...dormitoriosAsignados,
            [eventData.id]: data,
          });
          Object.keys(data ?? {}).forEach((habID) => {
            if (
              ["ALL", user.genero].includes(
                lugares[eventData.lugar] &&
                  lugares[eventData.lugar]["habitaciones"] &&
                  lugares[eventData.lugar]["habitaciones"][habID]
                    ?.genero_admitido
              )
            ) {
              Object.keys(data[habID]).forEach((userUID) =>
                setUpdatedParticipantData({
                  uid: userUID,
                })
              );
            }
          });
        },
        () => {
          dormitoriosSubs = undefined;
        }
      );
    }
    /* eslint-disable-next-line */
  }, [eventData]);

  return <SidebarLayout />;
};
