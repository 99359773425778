import {
  faInstagram,
  faTiktok,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBan,
  faEnvelope,
  faMars,
  faQrcode,
  faUser,
  faUserSecret,
  faUserTie,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken, lighten } from "@material-ui/core";
import { Box, IconButton, Tooltip, styled } from "@mui/material";
import { getGridSingleSelectOperators } from "@mui/x-data-grid";
import { differenceInYears } from "date-fns";

export const defaultTheme = "Camp2025Theme";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const errorMessages = {
  "auth/internal-error": "Error Interno, contacta al administrador",
  "auth/email-already-in-use": "Este correo ya se encuentra registrado",
  "auth/wrong-password": "Contraseña Incorrecta",
  "auth/user-not-found": "Usuario no Encontrado",
  "auth/weak-password": "La contraseña debe tener al menos 6 caracteres!",
  "auth/too-many-requests":
    "Demasiados intentos, intentalo luego o restablece tu contraseña",
  1001: "Este número de cédula ya se encuentra registrado",
  1003: "Error al autenticarte por firebase, contacta al administrador",
  1004: "No tienes permiso para iniciar sesión",
  1005: "Primero escribe un correo válido",
  1006: "En breve recibirás un correo. Revisa SPAM!",
};

export const getCorreoHelpLink = (correo) => {
  return `https://api.whatsapp.com/send?phone=593959278316&text=Hola%20Santiago!%20Te%20saludo%20desde%20el%20sistema%20de%20eventos%20de%20la%20iglesia%2C%20el%20correo%20que%20tengo%20registrado%20es%20*${correo}*%2C%20pero%20necesito%20un%20poco%20de%20ayuda%20%F0%9F%98%A5`;
};

//Same as in config.ts
export const aleatorio = (max) => {
  let value = Math.random() * max;
  return Math.floor(value);
};

//Same as in config.ts
export const randomFromArray = (arr) => {
  return arr[aleatorio(arr.length)];
};

//Same as in mailTemplate.ts
export const getSaludo = (nombre, wordEnding) => {
  const opts = [
    `¡Nos vemos allá ${nombre}!`,
    `¡Todo listo ${nombre}!`,
    `¡Hola ${nombre}!`,
    `¡Saludos ${nombre}!`,
    `¡Te esperamos ${nombre}!`,
    `¡Bendiciones ${nombre}!`,
    `¡Nos vemos ${nombre}!`,
    `¡Todo bien ${nombre}!`,
    `¡Bienvenid${wordEnding} ${nombre}!`,
  ];
  return randomFromArray(opts);
};

//Same as in mailTemplate.ts
export const getEntradaText = () => {
  const opts = [
    "Aquí está tu entrada",
    "Tu entrada",
    "Guarda tu entrada",
    "Esta es tu entrada",
    "Recuerda guardar tu entrada",
    "Hazle una captura a tu entrada",
    "No pierdas tu entrada",
  ];
  return randomFromArray(opts);
};

export const bloodTypes = ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"];

//Same as in config.ts
export const genders = [
  { value: "M", name: "Masculino" },
  { value: "F", name: "Femenino" },
];

/**
 *
 * @param {char} gender Letter specifying gender (M / F)
 * @param {string[]} endings Array of word endings, in order, first for M, then F
 */
export const getWordGender = (gender, endings = ["o", "a"]) => {
  const ends = {
    [genders[0].value]: endings[0],
    [genders[1].value]: endings[1],
  };
  return ends[genders.find((g) => g.value === gender).value];
};

export const getWANumber = (number) => {
  return (
    (number.startsWith("593") ? number : "593" + number.substring(1)) + "@c.us"
  );
};

export const normalizar = (cadena) => {
  const acentos = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    ñ: "n",
    Ñ: "N",
  };
  const sinTildes = cadena
    .split("")
    .map((letra) => acentos[letra] || letra)
    .join("")
    .toString();
  return sinTildes.normalize("NFD");
};

export const sleep = (ms) => {
  return new Promise((res) => setTimeout(() => res("me dormi por: " + ms), ms));
};

export const dateDiff = (date) => {
  return differenceInYears(new Date(), date);
};

export const capitalize = (input) => {
  if (input && input.length > 0) {
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        (element[0] || "").toUpperCase() + element.substring(1).toLowerCase()
      );
    });
    return CapitalizedWords.join(" ");
  }
  return "";
};

// SIMILAR as config.ts
export const QR_OPTIONS = {
  width: 300,
  height: 300,
  type: "svg",
  margin: 15,
  qrOptions: {
    typeNumber: 0,
    mode: "Byte",
    errorCorrectionLevel: "H",
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.5,
    margin: 0,
  },
  dotsOptions: {
    color: "#222222",
    type: "rounded",
  },
  backgroundOptions: {
    color: "transparent",
  },
  cornersSquareOptions: {
    color: "#222222",
    type: "extra-rounded",
  },
  cornersDotOptions: {
    color: "#222222",
    type: "dot",
  },
};

//FUNCION PARECIDA EN GLUZ-BACKEND -> CONFIG.JS
export const getQRcolors = (theme, apptheme) => {
  switch (apptheme) {
    case "Congreso2023Theme":
      return {
        dots: lighten(theme.palette.background.paper, 0.2),
        cornerSquares: darken(theme.palette.primary.dark, 0.2),
        cornerDots: theme.palette.primary.main,
      };
    case "ParadoxTheme":
      return {
        dots: darken(theme.palette.primary.dark, 0.1),
        cornerSquares: theme.palette.secondary.main,
        cornerDots: theme.palette.primary.light,
      };
    case "GLuzNavidad2023":
      return {
        dots: darken(theme.palette.primary.dark, 0.1),
        cornerSquares: theme.palette.secondary.main,
        cornerDots: theme.palette.primary.light,
      };
    case "Camp2024Theme":
      return {
        dots: "#6d6d6d",
        cornerSquares: darken(theme.palette.secondary.main, 0.3),
        cornerDots: darken(theme.palette.primary.dark, 0.1),
      };
    case "Camp2025Theme":
      return {
        dots: darken(theme.palette.primary.dark, 0.1),
        cornerSquares: theme.palette.secondary.main,
        cornerDots: theme.palette.primary.light,
      };
    default:
      return {
        dots: theme.palette.secondary.main,
        cornerSquares: theme.palette.primary.main,
        cornerDots: theme.palette.secondary.light,
      };
  }
};

export const filterDropdown = getGridSingleSelectOperators()
  .filter((operator) => operator.value === "is")
  .map((operator) => {
    const newOperator = { ...operator };
    const newGetApplyFilterFn = (filterItem) => {
      return (params) => {
        // eslint-disable-next-line
        return filterItem?.value == params.value;
      };
    };
    newOperator.getApplyFilterFn = newGetApplyFilterFn;
    return newOperator;
  });

export const gridButton = (params, tiposDeAsistentes) => {
  if (params.value !== "" && params.value !== undefined) {
    const config = {
      genero: {
        icon:
          params.value === "M"
            ? faMars
            : params.value === "F"
            ? faVenus
            : faBan,
        url: "",
        size: "18px",
        color:
          params.value === "M"
            ? "info"
            : params.value === "F"
            ? "primary"
            : "error",
        tooltip:
          genders.find((g) => g.value === params.value)?.name ||
          "No Registrado Aún",
      },
      tipo: {
        icon:
          params.value === "2"
            ? faUserTie
            : params.value === "0"
            ? faUser
            : params.value === "admin"
            ? faUserSecret
            : params.value === "checkIn"
            ? faQrcode
            : faUserSecret,
        url: "",
        size: "18px",
        color:
          params.value === "2"
            ? "warning"
            : params.value === "0"
            ? "primary"
            : params.value === "admin"
            ? "terciary"
            : params.value === "checkIn"
            ? "info"
            : "error",
        tooltip: (tiposDeAsistentes || {})[params.value],
      },
      celular_emergencias: {
        icon: faWhatsapp,
        url: `https://api.whatsapp.com/send?phone=593${params.value.substring(
          1
        )}&text=Hola+${
          (params.row?.contacto_emergencias || "").split(" ")[0]
        }!+👋🏻`,
        size: "22px",
        color: "secondary",
        tooltip: params.row?.contacto_emergencias || "",
      },
      celular: {
        icon: faWhatsapp,
        url: `https://api.whatsapp.com/send?phone=593${params.value.substring(
          1
        )}&text=Hola%20${(params.row.nombres || "").split(" ")[0]}! 👋🏻`,
        size: "22px",
        color: "success",
        tooltip: params.value,
      },
      correo: {
        icon: faEnvelope,
        url: "mailto:" + params.value,
        size: "18px",
        color: "primary",
        tooltip: params.value,
      },
      tiktok: {
        icon: faTiktok,
        url: "https://www.tiktok.com/@" + params.value,
        size: "17.5px",
        color: "primary",
        tooltip: "@" + params.value,
      },
      instagram: {
        icon: faInstagram,
        url: "https://www.instagram.com/" + params.value,
        size: "21px",
        color: "primary",
        tooltip: "@" + params.value,
      },
    };
    return (
      <Tooltip
        PopperProps={{ disablePortal: true }}
        title={config[params.field].tooltip}
        placement={"right"}
        arrow
      >
        <IconButton
          aria-label={config[params.field].tooltip}
          color={config[params.field].color}
          href={config[params.field].url}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={config[params.field].icon}
            style={{ fontSize: config[params.field].size }}
          />
        </IconButton>
      </Tooltip>
    );
  } else {
    return "N/A";
  }
};

export const closeSnackbar = (event, reason, setSnackbar) => {
  if (reason === "clickaway") {
    return;
  }
  setSnackbar(null);
};

export const MainContentScreen = styled(Box)(
  () => `
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`
);

export const MainContent = styled(Box)(
  ({ header_height }) => `
    min-height: calc(100vh - ${header_height || "0px"});
    max-height: calc(100vh - ${header_height || "0px"});
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`
);

export const beep =
  new Audio(`data:audio/wav;base64,//uQRAAAAWMSLwUIYAAsYkXgoQwAEaYLWfkWgAI0wWs/ItAAAGDgYtAgAyN+QWaAAihwMWm4G8QQRDiMcCBcH3Cc+CDv/7xA4Tvh9Rz/y8QADBwMWgQAZG/ILNAARQ4GLTcDeIIIhxGOBAuD7hOfBB3/94gcJ3w+o5/5eIAIAAAVwWgQAVQ2ORaIQwEMAJiDg95G4nQL7mQVWI6GwRcfsZAcsKkJvxgxEjzFUgfHoSQ9Qq7KNwqHwuB13MA4a1q/DmBrHgPcmjiGoh//EwC5nGPEmS4RcfkVKOhJf+WOgoxJclFz3kgn//dBA+ya1GhurNn8zb//9NNutNuhz31f////9vt///z+IdAEAAAK4LQIAKobHItEIYCGAExBwe8jcToF9zIKrEdDYIuP2MgOWFSE34wYiR5iqQPj0JIeoVdlG4VD4XA67mAcNa1fhzA1jwHuTRxDUQ//iYBczjHiTJcIuPyKlHQkv/LHQUYkuSi57yQT//uggfZNajQ3Vmz+Zt//+mm3Wm3Q576v////+32///5/EOgAAADVghQAAAAA//uQZAUAB1WI0PZugAAAAAoQwAAAEk3nRd2qAAAAACiDgAAAAAAABCqEEQRLCgwpBGMlJkIz8jKhGvj4k6jzRnqasNKIeoh5gI7BJaC1A1AoNBjJgbyApVS4IDlZgDU5WUAxEKDNmmALHzZp0Fkz1FMTmGFl1FMEyodIavcCAUHDWrKAIA4aa2oCgILEBupZgHvAhEBcZ6joQBxS76AgccrFlczBvKLC0QI2cBoCFvfTDAo7eoOQInqDPBtvrDEZBNYN5xwNwxQRfw8ZQ5wQVLvO8OYU+mHvFLlDh05Mdg7BT6YrRPpCBznMB2r//xKJjyyOh+cImr2/4doscwD6neZjuZR4AgAABYAAAABy1xcdQtxYBYYZdifkUDgzzXaXn98Z0oi9ILU5mBjFANmRwlVJ3/6jYDAmxaiDG3/6xjQQCCKkRb/6kg/wW+kSJ5//rLobkLSiKmqP/0ikJuDaSaSf/6JiLYLEYnW/+kXg1WRVJL/9EmQ1YZIsv/6Qzwy5qk7/+tEU0nkls3/zIUMPKNX/6yZLf+kFgAfgGyLFAUwY//uQZAUABcd5UiNPVXAAAApAAAAAE0VZQKw9ISAAACgAAAAAVQIygIElVrFkBS+Jhi+EAuu+lKAkYUEIsmEAEoMeDmCETMvfSHTGkF5RWH7kz/ESHWPAq/kcCRhqBtMdokPdM7vil7RG98A2sc7zO6ZvTdM7pmOUAZTnJW+NXxqmd41dqJ6mLTXxrPpnV8avaIf5SvL7pndPv
  PpndJR9Kuu8fePvuiuhorgWjp7Mf/PRjxcFCPDkW31srioCExivv9lcwKEaHsf/7ow2Fl1T/9RkXgEhYElAoCLFtMArxwivDJJ+bR1HTKJdlEoTELCIqgEwVGSQ+hIm0NbK8WXcTEI0UPoa2NbG4y2K00JEWbZavJXkYaqo9CRHS55FcZTjKEk3NKoCYUnSQ0rWxrZbFKbKIhOKPZe1cJKzZSaQrIyULHDZmV5K4xySsDRKWOruanGtjLJXFEmwaIbDLX0hIPBUQPVFVkQkDoUNfSoDgQGKPekoxeGzA4DUvnn4bxzcZrtJyipKfPNy5w+9lnXwgqsiyHNeSVpemw4bWb9psYeq//uQZBoABQt4yMVxYAIAAAkQoAAAHvYpL5m6AAgAACXDAAAAD59jblTirQe9upFsmZbpMudy7Lz1X1DYsxOOSWpfPqNX2WqktK0DMvuGwlbNj44TleLPQ+Gsfb+GOWOKJoIrWb3cIMeeON6lz2umTqMXV8Mj30yWPpjoSa9ujK8SyeJP5y5mOW1D6hvLepeveEAEDo0mgCRClOEgANv3B9a6fikgUSu/DmAMATrGx7nng5p5iimPNZsfQLYB2sDLIkzRKZOHGAaUyDcpFBSLG9MCQALgAIgQs2YunOszLSAyQYPVC2YdGGeHD2dTdJk1pAHGAWDjnkcLKFymS3RQZTInzySoBwMG0QueC3gMsCEYxUqlrcxK6k1LQQcsmyYeQPdC2YfuGPASCBkcVMQQqpVJshui1tkXQJQV0OXGAZMXSOEEBRirXbVRQW7ugq7IM7rPWSZyDlM3IuNEkxzCOJ0ny2ThNkyRai1b6ev//3dzNGzNb//4uAvHT5sURcZCFcuKLhOFs8mLAAEAt4UWAAIABAAAAAB4qbHo0tIjVkUU//uQZAwABfSFz3ZqQAAAAAngwAAAE1HjMp2qAAAAACZDgAAAD5UkTE1UgZEUExqYynN1qZvqIOREEFmBcJQkwdxiFtw0qEOkGYfRDifBui9MQg4QAHAqWtAWHoCxu1Yf4VfWLPIM2mHDFsbQEVGwyqQoQcwnfHeIkNt9YnkiaS1oizycqJrx4KOQjahZxWbcZgztj2c49nKmkId44S71j0c8eV9yDK6uPRzx5X18eDvjvQ6yKo9ZSS6l//8elePK/Lf//IInrOF/FvDoADYAGBMGb7FtErm5MXMlmPAJQVgWta7Zx2go+8xJ0UiCb8LHHdftWyLJE0QIAIsI+UbXu67dZMjmgDGCGl1H+vpF4NSDckSIkk7Vd+sxEhBQMRU8j/12UIRhzSaUdQ+rQU5kGeFxm+hb1oh6pWWmv3uvmReDl0UnvtapVaIzo1jZbf/pD6ElLqSX+rU
  mOQNpJFa/r+sa4e/pBlAABoAAAAA3CUgShLdGIxsY7AUABPRrgCABdDuQ5GC7DqPQCgbbJUAoRSUj+NIEig0YfyWUho1VBBBA//uQZB4ABZx5zfMakeAAAAmwAAAAF5F3P0w9GtAAACfAAAAAwLhMDmAYWMgVEG1U0FIGCBgXBXAtfMH10000EEEEEECUBYln03TTTdNBDZopopYvrTTdNa325mImNg3TTPV9q3pmY0xoO6bv3r00y+IDGid/9aaaZTGMuj9mpu9Mpio1dXrr5HERTZSmqU36A3CumzN/9Robv/Xx4v9ijkSRSNLQhAWumap82WRSBUqXStV/YcS+XVLnSS+WLDroqArFkMEsAS+eWmrUzrO0oEmE40RlMZ5+ODIkAyKAGUwZ3mVKmcamcJnMW26MRPgUw6j+LkhyHGVGYjSUUKNpuJUQoOIAyDvEyG8S5yfK6dhZc0Tx1KI/gviKL6qvvFs1+bWtaz58uUNnryq6kt5RzOCkPWlVqVX2a/EEBUdU1KrXLf40GoiiFXK///qpoiDXrOgqDR38JB0bw7SoL+ZB9o1RCkQjQ2CBYZKd/+VJxZRRZlqSkKiws0WFxUyCwsKiMy7hUVFhIaCrNQsKkTIsLivwKKigsj8XYlwt/WKi2N4d//uQRCSAAjURNIHpMZBGYiaQPSYyAAABLAAAAAAAACWAAAAApUF/Mg+0aohSIRobBAsMlO//Kk4soosy1JSFRYWaLC4qZBYWFRGZdwqKiwkNBVmoWFSJkWFxX4FFRQWR+LsS4W/rFRb/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////VEFHAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAU291bmRib3kuZGUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMjAwNGh0dHA6Ly93d3cuc291bmRib3kuZGUAAAAAAAAAACU=`);
