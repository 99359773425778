import { WhatsApp } from "@mui/icons-material";
import { Button, Card, Container, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import QRCodeStyling from "qr-code-styling-node";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import CustomLoading from "../../components/CustomLoading";
import { SiteContext } from "../../contexts/SiteContext";
import {
  currencyFormatter,
  getEntradaText,
  getQRcolors,
  getSaludo,
  getWordGender,
  MainContent,
  QR_OPTIONS,
} from "../../utils";

export const MyQR = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const {
    user = {},
    eventos,
    eventData,
    setEventData,
  } = useContext(SiteContext);
  const { eventID } = useParams();
  const [qrSize, setQrSize] = useState();
  const [saludoText, setSaludoText] = useState("");
  const [entradaText, setEntradaText] = useState("");

  useEffect(() => {
    const handleWindowResize = () => {
      setQrSize(
        window.innerWidth * 0.75 > QR_OPTIONS.width
          ? QR_OPTIONS.width
          : window.innerWidth * 0.75
      );
    };
    window.addEventListener("resize", handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (eventos && qrSize && user) {
      setLoading(true);
      setEventData(eventos[eventID]);
      setSaludoText(
        getSaludo(
          (user.nombres || "").split(" ")[0],
          getWordGender(user.genero || "M")
        )
      );
      setEntradaText(getEntradaText());
      const QRcolors = getQRcolors(theme, localStorage.getItem("appTheme"));
      const options = {
        ...QR_OPTIONS,
        width: qrSize,
        height: qrSize,
        dotsOptions: {
          type: "rounded",
          color: QRcolors.dots,
        },
        cornersSquareOptions: {
          type: "extra-rounded",
          color: QRcolors.cornerSquares,
        },
        cornersDotOptions: {
          type: "dot",
          color: QRcolors.cornerDots,
        },
        data: user.uid,
        image: eventos[eventID]?.logoQR,
      };
      const qrCode = new QRCodeStyling(options);
      const QR_DIV = document.getElementById("QR-CANVAS");
      if (QR_DIV.hasChildNodes()) {
        QR_DIV.innerHTML = "";
      }
      qrCode.append(QR_DIV);
      const QR_SVG = document.querySelector("#QR-CANVAS > svg");
      if (QR_SVG) {
        QR_SVG.style = "border-radius: 30px;";
      }
      // Forzamos una conversion para que cargue el QR y no se vea en blanco al inicio
      qrCode.getRawData("svg").then(() => setLoading(false));
    }
  }, [eventos, eventID, user, theme, qrSize]);

  return (
    <>
      <Helmet>
        <title>QR | {eventData?.nombre || ""}</title>
      </Helmet>
      <CustomLoading show={loading} logo={eventData?.logo} />
      <MainContent header_height={theme.header.height}>
        <div
          style={{
            overflow: "auto",
            padding: "25px 0px 25px 0px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "fit-content",
                height: "fit-content",
                padding: "15px",
                borderRadius: "40px",
                background: "white",
                border: "1px dashed rgb(141, 141, 141)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "30px",
                  border: "5px dotted rgb(141, 141, 141)",
                }}
              >
                <div
                  id="QR-CANVAS"
                  style={{
                    maxWidth: `${qrSize}px`,
                    maxHeight: `${qrSize}px`,
                  }}
                />
              </div>
            </div>
          </div>
          <Container maxWidth="sm">
            <Card sx={{ textAlign: "center", mt: 2, p: 3 }} elevation={10}>
              {eventData?.isActive ? (
                <>
                  <Typography variant="h2" sx={{ mb: 2 }}>
                    {saludoText}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                  >
                    {entradaText} para el {eventData?.descripcion}{" "}
                    <b>{`(${eventData?.nombre})`} ¡Debes presentarla!</b>
                    <br />
                    <br />*{" "}
                    {user.pagos && user.pagos[eventID] ? (
                      <>
                        {`Ya has completado el pago 🥳`}
                        <br />
                        <b>
                          ¡Preparate para el{" "}
                          {format(
                            new Date(eventData?.fecha_inicio || new Date()),
                            "eeee d 'de' MMMM 'a las' hh:mm aaa"
                          )}
                          !
                        </b>
                      </>
                    ) : (
                      <span>
                        Hasta ahora has abonado{" "}
                        <b>
                          {currencyFormatter.format(
                            user.abonos && user.abonos[eventID]
                              ? user.abonos[eventID]
                              : 0
                          )}
                        </b>
                        {eventData?.costo ? (
                          <>
                            {" de "}
                            <b>{currencyFormatter.format(eventData?.costo)}</b>
                          </>
                        ) : (
                          <></>
                        )}
                        <br />
                        <br />
                        {eventData.mensajeNoHaPagadoQR || (
                          <>
                            Tienes hasta el mismo día para culminar tu pago,
                            recuerda que si no has pagado no podrás asistir
                            :&#40;
                          </>
                        )}
                      </span>
                    )}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h2" sx={{ mb: 2 }}>
                    ¡Hola {(user.nombres || "").split(" ")[0]}!
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{ mb: 1 }}
                  >
                    Este evento ha finalizado :&#40;
                  </Typography>
                </>
              )}
              {eventData?.estado &&
              !(user.pagos && user.pagos[eventID] === true) ? (
                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  onClick={() => {
                    window.open(
                      // eslint-disable-next-line
                      (eventData.whatsAppURL || "")
                        .replaceAll(
                          "${nombre}",
                          (user.nombres || "").split(" ")[0]
                        )
                        .replaceAll("${cedula}", user.cedula)
                    );
                  }}
                >
                  <WhatsApp />
                  <div style={{ width: "5px" }} />
                  Ir a WhatsApp
                </Button>
              ) : (
                <></>
              )}
            </Card>
          </Container>
        </div>
      </MainContent>
    </>
  );
};
