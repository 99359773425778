export const themeList = [
  // Modificar nombre en {themeName}.js
  // Agregar theme en base.js
  {
    id: "YouthTogetherTheme", //config.ts
    mailSender: "YOUTH",
    nombre: "Youth Together (- FOCUS -)",
    colors: {
      primary: "#362684",
      secondary: "#FF31FA",
      terciary: "#838383",
      info: "#33C2FF",
      warning: "#FFC675",
      error: "#D02F4A",
      success: "#7DC46C",
      black: "#223354",
      lightBG: "#F1E6FF",
      lighterBG: "#fcf9ff",
      darkBG: "#302373",
      white: "#ffffff",
      primaryAlt: "#000C57",
    },
  },
  {
    id: "ParadoxTheme", //config.ts
    mailSender: "YOUTH",
    nombre: "Youth Together (- PARADOX -)",
    colors: {
      primary: "#8F59C9",
      secondary: "#F4C143",
      terciary: "#922D50",
      info: "#8991DC",
      warning: "#EDC05C",
      error: "#AF4545",
      success: "#72AF6D",
      black: "#ffffff",
      lightBG: "#EDDFFF",
      lighterBG: "#FFF5D0",
      darkBG: "#1E1C21",
      white: "#302E32",
      primaryAlt: "#000C57",
    },
  },
  {
    id: "GLuzTheme", //config.ts
    mailSender: "GLUZ",
    nombre: "Generación Luz",
    colors: {
      primary: "#A62D67",
      secondary: "#34528E",
      terciary: "#838383",
      info: "#48A1D8",
      warning: "#FC9C1E",
      error: "#D13539",
      success: "#7DC46C",
      black: "#223354",
      lightBG: "#E6ECFF",
      lighterBG: "#E6ECFF",
      darkBG: "#12192A",
      white: "#ffffff",
      primaryAlt: "#000C57",
    },
  },
  {
    id: "GLuzNavidad2023", //config.ts
    mailSender: "GLUZ",
    nombre: "Generación Luz - XMAS 2023",
    colors: {
      primary: "#003932",
      secondary: "#A21F25",
      terciary: "#8E632C",
      info: "#77ACD0",
      warning: "#F7DCBE",
      error: "#C3212D",
      success: "#7DC46C",
      black: "#1C2117 ",
      lightBG: "#F1DEBE",
      lighterBG: "#FAF8EB",
      darkBG: "#1B221B",
      white: "#ffffff",
      primaryAlt: "#095700",
    },
  },
  {
    id: "Congreso2023Theme", //config.ts
    mailSender: "YOUTH",
    nombre: "Congreso Youth Together (2023)",
    colors: {
      primary: "#79bf67",
      secondary: "#8bd976",
      terciary: "#AF4545",
      info: "#8991DC",
      warning: "#EDC05C",
      error: "#AF4545",
      success: "#72AF6D",
      black: "#ffffff",
      lightBG: "#EDDFFF",
      lighterBG: "#FFF5D0",
      darkBG: "#131312",
      white: "#1b1b1a",
      primaryAlt: "#282c27",
    },
  },
  {
    id: "Camp2024Theme", //config.ts
    mailSender: "YOUTH",
    nombre: "Youth Together Camp 2024",
    colors: {
      primary: "#BC4241",
      secondary: "#c1b8a2",
      terciary: "#a45650",
      info: "#8991DC",
      warning: "#EDC05C",
      error: "#AF4545",
      success: "#72AF6D",
      black: "#E9E9E9",
      lightBG: "#FFF5D0",
      lighterBG: "#FFFBE9",
      darkBG: "#040200",
      white: "#161616",
      primaryAlt: "#300101",
    },
  },
  {
    id: "Camp2025Theme", //config.ts
    mailSender: "GLUZ",
    nombre: "Campamento de Jóvenes 2025",
    colors: {
      primary: "#c353b9",
      secondary: "#ffb353",
      terciary: "#EFD9BD",
      info: "#9BB3DD",
      warning: "#FEF0DA",
      error: "#933842",
      success: "#759B6A",
      black: "#2E2033",
      lightBG: "#EEE8DE",
      lighterBG: "#FAF7EB",
      darkBG: "#2E2033",
      white: "#ffffff",
      primaryAlt: "#7A85AF",
    },
  },
];
export default themeList;
