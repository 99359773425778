import { Helmet } from "react-helmet-async";
import { useContext, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Snackbar,
  Alert,
  Backdrop,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  useTheme,
} from "@mui/material";
import { isValidForm } from "../components/CustomTextField";
import { CustomTextField } from "../components/CustomTextField";
import {
  defaultTheme,
  errorMessages,
  getCorreoHelpLink,
  MainContentScreen as MainContent,
  sleep,
} from "../utils";
import { LoadingButton } from "@mui/lab";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import {
  auth,
  database,
  registerNewUser,
  sendCustomResetPassLink,
} from "../config";
import { get, onValue, ref } from "firebase/database";
import { useNavigate, useParams } from "react-router-dom";
import MessagePage from "./MessagePage";
import { ThemeContext } from "../theme/ThemeProvider";
import CustomLoading from "../components/CustomLoading";
import {
  defaultFormData,
  FormularioDatos,
  writeUserData,
} from "../components/FormularioDatos";
import { SiteContext } from "../contexts/SiteContext";
import { CustomImage } from "../components/CustomImage";
import { LocalActivity } from "@mui/icons-material";

const Registro = () => {
  const {
    iglesias = {},
    setIglesias,
    setUserCedulas,
    eventos = {},
    defaultEventID,
  } = useContext(SiteContext);
  const theme = useTheme();
  const { eventID = defaultEventID } = useParams();
  const [formData, setFormData] = useState(defaultFormData(eventID));
  const [modalFormData, setModalFormData] = useState({ contrasena: "" });
  const [eventData, setEventData] = useState();
  const [tipoForm, setTipoForm] = useState("NEW");
  const [validateForm, setValidateForm] = useState(false);
  const [cedulas, setCedulas] = useState([]);
  const [correos, setCorreos] = useState([]);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(true);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingClean, setIsLoadingClean] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("Error");
  const [openDialog, setOpenDialog] = useState(false);
  const [correoReset, setCorreoReset] = useState();
  const [isLoadingLoginModal, setIsLoadingLoginModal] = useState(false);
  const [isLoadingResetMail, setIsLoadingResetMail] = useState(false);
  const [goToConfirmation, setGoToConfirmation] = useState(false);
  const setTheme = useContext(ThemeContext);
  const navigate = useNavigate();

  let subs1, subs2;
  useEffect(() => {
    //Obtenemos listado de IGLESIAS
    const iglesiasRef = ref(database, "iglesias/");
    if (Object.keys(iglesias).length === 0) {
      subs1 = onValue(iglesiasRef, (snapshot) => {
        const data = {};
        // Forzamos a que JS lea como objeto, no como arreglos
        Object.keys(snapshot.val()).forEach((prop) => {
          data[prop.toString()] = snapshot.val()[prop];
        });
        setIglesias(data);
      });
    }
    //Obtenemos listado de cedulas registradas
    const cedulasRef = ref(database, "usersCedulas/");
    if (cedulas.length === 0 && correos.length === 0) {
      subs2 = onValue(cedulasRef, (snapshot) => {
        const data = snapshot.val();
        setUserCedulas(data);
        setCedulas(Object.keys(data));
        setCorreos(Object.values(data));
      });
    }
    if (!eventData) {
      //Obtenemos los datos del EVENTO especificado por URL
      const fetchEvento = async () => {
        if (Object.keys(eventos).length === 0) {
          const eventosRef = ref(database, `eventos/${eventID}`);
          const eventInfo = (await get(eventosRef)).val();
          setEventData(eventInfo); // ?? valida null y undefined
          setTheme(eventInfo?.themeName || defaultTheme);
          await sleep(500);
          setIsLoadingGlobal(false);
        } else if (eventos[eventID]) {
          // Get date from prev retrieved event
          const eventInfo = eventos[eventID];
          setEventData(eventInfo); // ?? valida null y undefined
          setTheme(eventInfo?.themeName || defaultTheme);
          await sleep(500);
          setIsLoadingGlobal(false);
        }
      };
      fetchEvento();
    }
    return () => {
      if (typeof subs1 === "function") subs1();
      if (typeof subs2 === "function") subs2();
    };
    // eslint-disable-next-line
  }, []);

  const validateUser = async (userData) => {
    // Validamos que esté NO registrado en el evento actual
    if (userData?.eventos && userData?.eventos[eventID]) {
      // Redireccionamos a la confirmacion
      setGoToConfirmation(true);
    } else {
      setTipoForm("UPDATE");
      loadUserData(userData);
      setIsLoadingGlobal(false);
    }
  };

  let subscriber, subsUserData;
  useEffect(() => {
    if (!subscriber) {
      console.log("agrgué listener");
      subscriber = onAuthStateChanged(auth, async (user) => {
        console.log("cambie el estado del usuario");
        if (user) {
          // IF User is signed in
          const usuarioRef = ref(database, `users/${user.uid}`);
          if (!subsUserData) {
            console.log("* [agrego listener de usuario actual] *");
            subsUserData = onValue(usuarioRef, (snapshot) => {
              console.log(`[Pedí data de usuario actual]`);
              const userData = { ...snapshot.val(), uid: user.uid };
              validateUser(userData);
            });
          } else {
            // Obtenemos data de usuario actualcon get, no con subscriber
            const snapshot = await get(usuarioRef);
            const userData = { ...snapshot.val(), uid: user.uid };
            validateUser(userData);
          }
        } else {
          if (tipoForm === "UPDATE") {
            clearFields();
          }
        }
      });
    }
    return () => {
      if (typeof subscriber === "function") subscriber();
      if (typeof subsUserData === "function") subsUserData();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (goToConfirmation === true) {
      if (typeof subs1 === "function") subs1();
      if (typeof subs2 === "function") subs2();
      if (typeof subscriber === "function") subscriber();
      if (typeof subsUserData === "function") subsUserData();
      navigate("/confirmacion/" + eventID, { replace: true });
    }
  }, [goToConfirmation]);

  const loadUserData = (data) => {
    setFormData({ ...formData, ...data });
  };

  const registrar = async () => {
    try {
      isValidForm(
        formData,
        correos,
        eventData?.formulario ? Object.keys(eventData.formulario) : undefined,
        eventData
      );
      if (cedulas.includes(formData["cedula"])) {
        // eslint-disable-next-line
        throw { code: 1001 };
      }

      //Registramos el usuario en el admin SDK de firebase
      await registerNewUser({ formData, eventID });

      //Iniciamos sesión con el usuario recién creado
      await signInWithEmailAndPassword(auth, formData.correo, formData.cedula);

      //Todo bien, se escribió su data a firebase
      setGoToConfirmation(true);
    } catch (error) {
      console.log("codigo error", error.code);
      setSnackbarMessage(errorMessages[error.code] ?? error.message);
      setSnackbarType("error");
      setOpenSnackbar(true);
      setIsLoadingSend(false);
    }
  };

  const registrarOldUser = async () => {
    try {
      isValidForm(
        formData,
        correos.filter((c) => c !== auth.currentUser.email),
        eventData?.formulario ? Object.keys(eventData.formulario) : undefined,
        eventData
      );
      await writeUserData(
        formData,
        auth.currentUser.uid,
        new Date().toISOString(),
        eventID
      );
      //Todo bien, se actualizó su data en firebase
      setGoToConfirmation(true);
    } catch (error) {
      console.log("codigo error", error.code);
      setSnackbarMessage(errorMessages[error.code] ?? error.message);
      setSnackbarType("error");
      setOpenSnackbar(true);
      setIsLoadingSend(false);
    }
  };

  const verifyCedula = async (data) => {
    const pos = cedulas.indexOf(data.cedula);
    if (pos !== -1) {
      setIsLoadingGlobal(true);
      const user = correos[pos];
      const pass = cedulas[pos];
      signInWithEmailAndPassword(auth, user, pass)
        .catch((error) => {
          setCorreoReset(user);
          setOpenDialog(true);
          setIsLoadingGlobal(false);
        })
        .finally(() => setIsLoadingGlobal(false));
    }
  };

  const clearFields = async () => {
    console.log("Limpio los campos...");
    setIsLoadingClean(true);
    if (auth?.signOut) await auth.signOut();
    setValidateForm(false);
    setFormData(defaultFormData());
    setTipoForm("NEW");
    setIsLoadingClean(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseDialog = () => {
    setFormData({ ...formData, cedula: "" });
    setOpenDialog(false);
  };

  const login = () => {
    setIsLoadingLoginModal(true);
    try {
      isValidForm(modalFormData);
      // Intenta hacer login nuevamente
      signInWithEmailAndPassword(auth, correoReset, modalFormData["contrasena"])
        .then((userCred) => {
          setOpenDialog(false);
        })
        .catch((error) => {
          setSnackbarMessage(errorMessages[error.code] ?? error.message);
          setSnackbarType("error");
          setOpenSnackbar(true);
        });
    } catch (error) {
      setSnackbarMessage(error?.message || "Contraseña no válida");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
    setIsLoadingLoginModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Registro | {eventData?.nombre || ""}</title>
        <meta property="og:title" content="¡Registrate!" />
        <meta property="og:description" content={eventData?.descripcion} />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://jovenes.iglesiabautistaisrael.com/registro/${eventID}`}
        />
        <meta property="og:image" content={eventData?.metaTagBanner} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="676" />
        <meta property="og:image:alt" content={eventData?.nombre} />
      </Helmet>
      <CustomLoading logo={eventData?.logo} show={isLoadingGlobal} />
      {!eventData ? (
        <MessagePage
          errorSubMessage={`Este formulario no existe, verifica que el link de inscripción esté correctamente escrito :(`}
          errorMessage={`Formulario no encontrado`}
        />
      ) : eventData?.estado === false ? (
        <MessagePage
          errorSubMessage={`El formulario de registro para ${eventData?.nombre} se encuentra deshabilitado :(`}
          errorMessage={`Registro Inactivo`}
        />
      ) : (
        <>
          <div style={{ overflow: "auto", maxHeight: "100%" }}>
            <MainContent>
              <Container
                maxWidth="sm"
                style={{ paddingTop: "10px", paddingBottom: "30px" }}
              >
                {eventData?.nombre !== "- METANOIA -" ? (
                  <Grid container padding={2} rowGap={0.25}>
                    <Grid item xs={12} textAlign={"center"}>
                      <Typography
                        style={{
                          fontFamily: "Bebas Neue",
                          letterSpacing: "5px",
                          fontWeight: "bold",
                          fontSize: "calc(2em + 1vw)",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        {eventData?.nombre}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        fontSize={"15px"}
                        display="flex"
                        justifyContent="center"
                      >
                        {eventData?.descripcion}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <div style={{ height: "15px" }} />
                )}
                <FormularioDatos
                  eventData={eventData}
                  formData={formData}
                  setFormData={setFormData}
                  validateForm={validateForm}
                  verifyCedula={verifyCedula}
                  iglesias={iglesias}
                  disabledFields={[
                    tipoForm === "UPDATE" ? "cedula" : undefined,
                    tipoForm === "UPDATE" ? "fecha_de_nacimiento" : undefined,
                    tipoForm === "UPDATE" ? "nombres" : undefined,
                    tipoForm === "UPDATE" ? "apellidos" : undefined,
                  ]}
                  visibleFields={
                    eventData?.formulario
                      ? Object.keys(eventData.formulario)
                      : undefined
                  }
                  headerBanner={
                    eventData?.publicidades?.[0] ? (
                      <CustomImage
                        alt="Banner"
                        src={
                          eventData?.publicidades
                            ? eventData.publicidades[0]
                            : eventData?.logo
                        }
                        style={{
                          //objectFit: "cover",
                          //objectPosition: "50% 32.5%",
                          //maxHeight: "350px",
                          //minHeight: "350px",
                          borderRadius: "25px",
                          width: "100%",
                        }}
                      />
                    ) : undefined
                  }
                  footer={
                    <>
                      {eventData?.costo &&
                      eventData?.costoVisibleEnInscripcion ? (
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                        >
                          <Alert
                            //severity="warning"
                            iconMapping={{
                              success: <LocalActivity fontSize="inherit" />,
                            }}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Recuerda que el costo del campamento es de
                            <b> ${eventData?.costo}</b>
                          </Alert>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {tipoForm === "UPDATE" ? (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          display="flex"
                          justifyContent="center"
                        >
                          <LoadingButton
                            fullWidth
                            variant="outlined"
                            color="primary"
                            loading={isLoadingClean}
                            onClick={clearFields}
                          >
                            LIMPIAR CAMPOS
                          </LoadingButton>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={tipoForm === "UPDATE" ? 6 : 12}
                        display="flex"
                        justifyContent="center"
                      >
                        <LoadingButton
                          fullWidth
                          variant="contained"
                          color="secondary"
                          loading={isLoadingSend}
                          onClick={async () => {
                            setValidateForm(true);
                            setIsLoadingSend(true);
                            switch (tipoForm) {
                              case "UPDATE":
                                registrarOldUser();
                                break;
                              case "NEW":
                                registrar();
                                break;
                              default:
                                setIsLoadingSend(false);
                            }
                          }}
                        >
                          REGISTRARSE
                        </LoadingButton>
                      </Grid>
                    </>
                  }
                />
              </Container>
            </MainContent>
          </div>
        </>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{ elevation: 5 }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Bebas Neue",
            letterSpacing: "2.5px",
            paddingBottom: "7.5px",
          }}
        >
          Inicia Sesión
        </DialogTitle>
        <DialogContent style={{ paddingBottom: "0px", textAlign: "center" }}>
          <DialogContentText>
            Al parecer has cambiado tu contraseña, para continuar con la
            inscripción debes ingresarla por motivos de seguridad, si por alguna
            razón no la recuerdas puedes hacer click en <b>"Me Olvidé"</b>,
            enviaremos un link a tu correo registrado:
          </DialogContentText>
          <div
            style={{
              margin: "10px 0px",
              fontSize: "15px",
            }}
          >
            <b>{correoReset}</b>
            <br />
            <Link
              href={getCorreoHelpLink(correoReset)}
              target="_blank"
              color="secondary"
              underline="always"
              fontSize="12px"
            >
              {"Ese ya no es mi correo 😢"}
            </Link>
          </div>
          <CustomTextField
            required
            fullWidth
            validateField
            formData={modalFormData}
            type="password"
            autocomplete="password"
            setFormData={setModalFormData}
            placeholder="*********"
            label="Contraseña"
            onEnterCallback={login}
          />
        </DialogContent>
        <DialogActions
          style={{ padding: "15px 24px 20px 24px", marginTop: "-15px" }}
        >
          <LoadingButton
            loading={isLoadingResetMail}
            color={"terciary"}
            onClick={async () => {
              setIsLoadingResetMail(true);
              try {
                await sendCustomResetPassLink({
                  correo: correoReset,
                  themeName: localStorage.getItem("appTheme"),
                  emailSender: eventData?.emailSender,
                });
                setSnackbarMessage(`Listo! ${errorMessages[1006]}`);
                setSnackbarType("success");
              } catch (error) {
                setSnackbarMessage(error?.message);
                setSnackbarType("error");
              }

              setOpenSnackbar(true);
              setIsLoadingResetMail(false);
            }}
            variant="outlined"
          >
            {"Me Olvidé :("}
          </LoadingButton>
          <LoadingButton
            loading={isLoadingLoginModal}
            onClick={login}
            variant="contained"
          >
            Continuar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Backdrop open={openSnackbar} style={{ zIndex: "10000" }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={snackbarMessage.length <= 50 ? 1750 : 5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbarType}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Backdrop>
    </>
  );
};

export default Registro;
